.App,
input {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}

input {
  border-radius: 0.5rem;
  border: 0;
  padding: 0.5rem;
}
p {
  max-width: 1000px;
}
nav {
  text-align: left;
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
nav a,
.unstyled-link {
  text-decoration: none;
  color: white;
}
.App-header {
  background-color: #1e3461;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
}
footer {
  font-size: 12px;
  text-align: center;
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: fixed;
  width: 100%;
  color: white;
  bottom: 0;
}
@media screen and (max-height: 900px) {
  .App-header {
    justify-content: flex-start;
  }
  .flex-font-big {
    margin-top: 15vh;
  }
  .flex-font-small {
    margin-top: 15vh;
  }
}
.flex-font-big {
  font-size: calc(10px + 2vmin);
}

.flex-font-small {
  font-size: calc(6px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
